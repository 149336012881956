import {
  MutatePolicyDTO,
  PolicyApi,
  QueryPolicyPaginateEntitiesDTO,
  RawPolicyPaginatedResponseDTO,
  ValidateResourcesDTO,
} from '@audioeye/auth-client';

import { APP_CONFIG } from '../../config';
import { client } from './client';

const policyApi = new PolicyApi(undefined, APP_CONFIG.auth.serviceUrl, client);
export const getRawPolicies = async ({
  pageSize,
  orderBy,
  sortDirection,
  cursor,
  policyType,
  principal,
  domain,
  resource,
  filterBy = '',
}: QueryPolicyPaginateEntitiesDTO & { filterBy?: string }): Promise<RawPolicyPaginatedResponseDTO> => {
  const result = await policyApi.getRawPolicys(
    pageSize,
    orderBy,
    sortDirection,
    cursor,
    policyType,
    principal,
    domain,
    resource,
  );
  const returnData = result.data;
  returnData.results = returnData.results.filter(
    (policy) => policy.resource.toLowerCase().indexOf(filterBy?.toLowerCase() ?? '') !== -1,
  );
  return returnData;
};

export const getPrincipalPolicyAndRoles = async (aeyeId: string) => {
  const result = await policyApi.getPrincipalPolicyAndRoles(aeyeId);
  return result.data;
};

export const validateResources = async (dto: ValidateResourcesDTO): Promise<boolean[]> => {
  const result = await policyApi.validateResources(dto);
  return result.data;
};

export const addResourcePolicy = async (dto: MutatePolicyDTO): Promise<void> => {
  await policyApi.addResourcePolicyForGroup(dto);
};

export const removeResourcePolicyById = async (policyId: number): Promise<void> => {
  await policyApi.removeResourcePolicyById(policyId);
};
