import {
  CreateSitesDTO,
  IssueDetailResponseDto,
  LegalFormSubmitDto,
  MutateSiteDTO,
  PageIssueReportDto,
  QuerySiteCountDTO,
  QuerySitePaginateEntitiesDTO,
  ReportShareDto,
  SiteApi,
  SiteDTO,
  SitePaginatedResponseDTO,
  SiteReportingDataDto,
  SiteSubscriptionAttestationResponseDTO,
  SiteValidityResponseDto,
  SpringtimeStatusDTO,
  StarReportLinkDto,
  TestResultCode,
  TransferSiteDTO,
  UpdatedLegalFormSubmitDto,
  UpdateSiteConfigDto,
  UpdateUserSiteSubscriptionDto,
  UserApi,
  UserSiteResponseDto,
  V2SiteApi,
} from '@audioeye/mono-client';

import { APP_CONFIG } from '../../config';
import { HelpArticleResponse } from '../../types/platform';
import { client } from './client';

const userApi = new UserApi(undefined, APP_CONFIG.api.url, client);
const siteApi = new SiteApi(undefined, APP_CONFIG.api.url, client);
const siteApiV2 = new V2SiteApi(undefined, APP_CONFIG.api.url, client);

export type GetSiteDto = { siteId: string };

export const getSite = async (siteId: string): Promise<SiteDTO> => {
  const result = await siteApiV2.getSite(siteId);

  return result.data;
};

export const getSiteDomainForLegacyId = async (legacyId: number): Promise<string> => {
  const result = await siteApi.getSiteDomainForLegacyId(legacyId);

  return result.data;
};

export const getPaginatedSites = async ({
  pageSize,
  orderBy,
  sortDirection,
  cursor,
  filterBy,
  accountId,
  subBrandId,
  domain,
  jsInstalled,
  subscriptionStatus,
  isConsentManagementEnabled,
}: QuerySitePaginateEntitiesDTO): Promise<SitePaginatedResponseDTO> => {
  const result = await siteApiV2.getSites(
    pageSize,
    orderBy ?? ['created'],
    sortDirection,
    cursor,
    filterBy,
    accountId,
    subBrandId,
    domain,
    jsInstalled,
    subscriptionStatus,
    isConsentManagementEnabled,
  );

  return result.data;
};

export const getSiteAttestation = async (siteId: string): Promise<SiteSubscriptionAttestationResponseDTO> => {
  const result = await siteApi.getSiteAttestation(siteId);

  return result.data;
};

export const provisionSite = async (dto: MutateSiteDTO): Promise<SiteDTO> => {
  const result = await siteApiV2.createSite(dto);

  return result.data as SiteDTO;
};

export const createSites = async (dto: CreateSitesDTO): Promise<void> => {
  await siteApiV2.createSites(dto);
};

export const cancelSite = async ({ siteId }: GetSiteDto) => {
  const result = await siteApiV2.cancelUserSiteSubscription(siteId);

  return result.data;
};

export const transferManuallyBilledSite = async ({
  siteId,
  ...dto
}: GetSiteDto & TransferSiteDTO): Promise<SiteDTO> => {
  const result = await siteApiV2.transferManuallyBilledSite(siteId, dto);

  return result.data;
};

export const updateSite = async ({ siteId, ...dto }: GetSiteDto & UpdateUserSiteSubscriptionDto): Promise<SiteDTO> => {
  const result = await siteApiV2.updateUserSiteSubscription(siteId, dto);

  return result.data;
};

export const updateSiteUrl = async ({ siteId, url }: { siteId: string; url: string }): Promise<UserSiteResponseDto> => {
  const result = await siteApi.updateSiteUrl(siteId, { url });

  return result.data;
};

export const getSiteURLValidity = async (url: string): Promise<SiteValidityResponseDto> => {
  const result = await siteApi.getSiteValidity(url);

  return result.data;
};

export const getHelpArticle = async (articleId: number) => {
  const url = `https://help.audioeye.com/api/v2/help_center/en-us/articles/${articleId}.json`;
  const result = await client.get<HelpArticleResponse>(url);

  return result.data;
};

export const submitLegalSupportEmail = async (userId: string, data: LegalFormSubmitDto) => {
  await userApi.legalFormSubmit(userId, data);
};

export const submitUpdatedLegalSupportEmail = async (userId: string, data: UpdatedLegalFormSubmitDto) => {
  await userApi.updatedLegalFormSubmit(userId, data);
};

export const getStarPlanLink = async ({ siteId }: GetSiteDto): Promise<StarReportLinkDto> => {
  const result = await siteApiV2.getStarReportLink(siteId);

  return result.data;
};

export const updateSiteConfig = async ({ siteId, ...dto }: GetSiteDto & UpdateSiteConfigDto): Promise<SiteDTO> => {
  const result = await siteApiV2.updateSiteConfig(siteId, dto);

  return result.data;
};

export const publishSiteRems = async (siteId: string): Promise<true> => {
  // NOTE: This endpoint doesn't return any data
  await siteApi.publishRem(siteId);

  return true;
};

export const getSiteCount = async ({
  filterBy,
  accountId,
  subBrandId,
  domain,
  jsInstalled,
  subscriptionStatus,
  isConsentManagementEnabled,
}: QuerySiteCountDTO): Promise<number> => {
  const result = await siteApiV2.getSiteCount(
    filterBy,
    accountId,
    subBrandId,
    domain,
    jsInstalled,
    subscriptionStatus,
    isConsentManagementEnabled,
  );

  return result.data;
};

export const getSiteReportingSummary = async ({
  endDate,
  reportShareId,
  siteId,
}: {
  endDate?: string;
  reportShareId?: string;
  siteId: string;
}): Promise<SiteReportingDataDto> => {
  const result = await siteApi.getSiteReportingSummaryData(siteId, endDate, undefined, reportShareId);

  return result.data;
};

export const getSitePageIssueReport = async (
  siteId: string,
  startDate?: string,
  endDate?: string,
  reportShareId?: string,
): Promise<PageIssueReportDto[]> => {
  const result = await siteApi.getSitePageIssueReportingData(siteId, endDate, startDate, reportShareId);
  return result.data;
};

export const fetchDomainIssueReportPagesByIssue = async (
  siteId: string,
  code: TestResultCode,
  startDate?: string,
  endDate?: string,
  reportShareId?: string,
): Promise<IssueDetailResponseDto> => {
  const result = await siteApi.fetchDomainIssueReportPagesByIssue(code, siteId, endDate, startDate, reportShareId);

  return result.data;
};

export const getReportShare = async (reportShareIdId: string): Promise<ReportShareDto> => {
  const result = await siteApi.getReportShare(reportShareIdId);

  return result.data;
};

export const deleteSite = async (siteId: string): Promise<void> => {
  await siteApiV2.deleteSite(siteId);
};

export const getSpringtimeStatusForSites = async (siteIds: string[]): Promise<SpringtimeStatusDTO[]> => {
  const result = await siteApiV2.getSpringTimeStatusForSites(siteIds);
  return result.data;
};
