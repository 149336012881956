import { ComponentConfigurationDTO, PortalComponentType } from '@audioeye/mono-client';

interface NavigationBaseItem {
  label: React.ReactNode;
  icon?: React.ReactNode;
}

export interface NavigationLinkItem extends NavigationBaseItem {
  type: 'link';
  route?: string;
  externalRoute?: boolean;
  portalComponentType: PrimaryPortalComponentType | PrimaryPortalComponentTypeLegalHelpChanges;
}

export interface NavigationButtonItem extends NavigationBaseItem {
  type: 'button';
  action: (params: unknown) => void | Promise<void>;
}

export type MenuOption = {
  key: string;
  menuLabel: string;
  name?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  badge?: React.ReactNode;
  link?: string;
  externalLink?: string;
  condition?: () => boolean;
};

export type NavigationMenuSection = {
  id: string;
  items: (MenuOption | undefined | null)[]; // undefined so you can just filter it in the render function
};

export interface NavigationMenuItem extends NavigationBaseItem {
  type: 'menu';
  menuSections: NavigationMenuSection[];
}

export type SiteSettingsType = PortalComponentType | ACCOUNT_MENU_OPTION_KEYS.NAV_DIVIDER;

export enum ACCOUNT_MENU_OPTION_KEYS {
  PROFILE = 'profile',
  ACCOUNT_SETTINGS = 'account_settings',
  HELP = 'help',
  DEVELOPER_DOCUMENTATION = 'developer_documentation',
  LOG_OUT = 'log_out',
  NAV_DIVIDER = 'divider',
  SITE_MANAGEMENT = 'site_management',
}

export type PrimaryComponentConfigurationDTO = Omit<ComponentConfigurationDTO, 'type'> & {
  type: PrimaryPortalComponentType | PrimaryPortalComponentTypeLegalHelpChanges;
};

export type PrimaryPortalComponentType =
  | typeof PortalComponentType.DASHBOARD
  | typeof PortalComponentType.REPORTS
  | typeof PortalComponentType.ACCESSIBILITY_SCANNER
  | typeof PortalComponentType.EXPERT_AUDIT;

export type PrimaryPortalComponentTypeLegalHelpChanges =
  | typeof PortalComponentType.DASHBOARD
  | typeof PortalComponentType.REPORTS
  | typeof PortalComponentType.ACCESSIBILITY_SCANNER
  | typeof PortalComponentType.EXPERT_AUDIT
  | typeof PortalComponentType.DOCUMENTATION;

export const PRIMARY_LINKS: PrimaryPortalComponentType[] = [
  PortalComponentType.DASHBOARD,
  PortalComponentType.REPORTS,
  PortalComponentType.ACCESSIBILITY_SCANNER,
  PortalComponentType.EXPERT_AUDIT,
];

export const PRIMARY_LINKS_LEGAL_HELP_CHANGES: PrimaryPortalComponentTypeLegalHelpChanges[] = [
  PortalComponentType.DASHBOARD,
  PortalComponentType.REPORTS,
  PortalComponentType.ACCESSIBILITY_SCANNER,
  PortalComponentType.EXPERT_AUDIT,
  PortalComponentType.DOCUMENTATION,
];

export const DASHBOARD_LINK_LOADING_WIDTHS: Record<PrimaryPortalComponentTypeLegalHelpChanges, number> = {
  [PortalComponentType.DASHBOARD]: 110,
  [PortalComponentType.REPORTS]: 85,
  [PortalComponentType.ACCESSIBILITY_SCANNER]: 175,
  [PortalComponentType.EXPERT_AUDIT]: 120,
  [PortalComponentType.DOCUMENTATION]: 150,
};
