import {
  A11yRulesConfigAvailableDTO,
  A11yRulesConfigLineageDTO,
  A11yRulesConfigOverridesApi,
  A11yRulesConfigStoredValueDTO,
  A11yRulesConfigValueWithRulesVersionDTO,
} from '@audioeye/mono-client';

import { APP_CONFIG } from '../../config';
import { client } from './client';

const a11yRulesConfigOverridesApi = new A11yRulesConfigOverridesApi(undefined, APP_CONFIG.api.url, client);

export const getAllRulesVersions = async (): Promise<A11yRulesConfigAvailableDTO[]> => {
  const result = await a11yRulesConfigOverridesApi.getAvailableVersions();
  return result.data;
};

export const getCurrentGlobalVersion = async (): Promise<A11yRulesConfigStoredValueDTO> => {
  const result = await a11yRulesConfigOverridesApi.getRootConfig();
  return result.data;
};

export const getLineage = async (entityId: string): Promise<A11yRulesConfigLineageDTO> => {
  const result = await a11yRulesConfigOverridesApi.getLineage(entityId);
  return result.data;
};

export const updateCurrentGlobalVersion = async (dto: A11yRulesConfigStoredValueDTO): Promise<void> => {
  await a11yRulesConfigOverridesApi.setRootConfig(dto);
};

export const getCurrentEntityVersion = async (entityId: string): Promise<A11yRulesConfigStoredValueDTO> => {
  const result = await a11yRulesConfigOverridesApi.getForEntity(entityId);
  return result.data;
};

export const updateCurrentEntityVersion = async (
  entityId: string,
  dto: A11yRulesConfigStoredValueDTO,
): Promise<void> => {
  await a11yRulesConfigOverridesApi.setForEntity(entityId, dto);
};

export const clearCurrentEntityVersion = async (entityId: string): Promise<void> => {
  await a11yRulesConfigOverridesApi.clearEntity(entityId);
};

export const getResolvedReportingConfigForSite = async (
  siteId: string,
  reportShareId?: string,
): Promise<A11yRulesConfigValueWithRulesVersionDTO> => {
  const result = await a11yRulesConfigOverridesApi.getResolvedById(siteId, reportShareId);
  return result.data;
};
