export interface UseAsyncOptions {
  shouldReThrow?: boolean;
  excludeErrorInAnalytics?: boolean;
}

export enum Gate {
  MagentoInstructions = 'magento_instructions',
  A11yHealthAdvisor = 'a11y-health-advisor',
  ShouldShowExpertServicesAd = 'should_show_expert_services_ad',
  ScriptsAutoInstalled = 'is_ae_script_automatically_installed',
  PreJSDashboardUpdate = 'pre_js_dashboard_update',
  ShowAccountProfilePage = 'portal_is_account_profile_page_enabled',
  ShouldShowVendastaNavBar = 'portal_show_vendasta_nav_bar',
  ShowUserDropdownInHeader = 'portal_show_user_dropdown_in_header',
  ShowSitesPageAddSiteButton = 'portal_show_sites_page_add_site_button',
  CanManageSubscriptions = 'can_manage_subscription',
  ShouldShowConstructionPage = 'should_show_construction_page',
  ShowLegalSupport = 'show_legal_support',
  ReportingVersionControl = 'reporting_version_control',
  AdminPolicyTableEnabled = 'admin_policy_table_enabled',
  ShouldShowAdminLiveMonitoringEditor = 'should_show_admin_live_monitoring_editor',
  AudioEyeAccounts = 'audioeye_accounts',
  DisableBulkFixerForm = 'disable_bulk_fixer_form',
  ShouldShowAccountSettings = 'should_show_account_settings',
  ShouldShowLegalTemplates = 'should_show_legal_templates',
  RemediationsAiSuggestions = 'remediations_ai_suggestions',
  // JOY UI change gates
  ShouldShowNewPortalJoyNav = 'should_show_new_portal_joy_nav',
  ShouldUseNewSiteManagement = 'should_use_new_site_management',
  ShouldUseJoyifiedRemAdmin = 'joyified_rem_admin',
  IsSiteSwitcherEnabled = 'is_site_switcher_enabled',
  ShouldUseLiveChargebee = 'should_use_live_chargebee',
  ShowDeveloperDocsLink = 'show_developer_docs_link',
  ShowLogoutLink = 'show_logout_link',
  ShouldShowLegalHelpChanges = 'should_show_legal_help_changes',
  ShouldShowLegalHelp = 'should_show_legal_help',
  ShouldShowStyleRemediationCreateButton = 'should_show_style_remediation_create_button',
  ShouldShowLegalSupportTab = 'should_show_legal_support_tab',
  // Springtime Gates
  ConsentMaxDevelopment = 'consent_max_development_gate',
}

export enum DynamicConfig {
  SiteAHACapabilities = 'site_aha_capabilities',
  UserAHACapabilities = 'user_aha_capabilities',
  UnderConstructionTime = 'under_construction_time',
}
