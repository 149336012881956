import {
  AddSitesToSiteGroup,
  CreateSiteGroupDTO,
  GetSiteGroupMetadataDTO,
  GetSiteGroupSitesParamsDto,
  MutateSiteGroupDTO,
  QuerySiteGroupPaginateEntitiesDTO,
  RefreshSiteConfigsForGroupIdParamsDto,
  SiteDTO,
  SiteGroupApi,
  SiteGroupDTO,
  SiteGroupMetadataDTO,
  SiteGroupPaginatedResponseDTO,
  SitePaginatedResponseDto,
} from '@audioeye/mono-client';
import { MutateSitesToSiteGroupParams } from 'types/siteGroup';

import { APP_CONFIG } from '../../config';
import { client } from './client';

const siteGroupApi = new SiteGroupApi(undefined, APP_CONFIG.api.url, client);
export const getSiteGroups = async ({
  pageSize,
  orderBy,
  sortDirection,
  cursor,
  type,
  filterBy,
  accountId,
  subBrandId,
  siteId,
  siteCreationDefaultId,
  isConsentManagementEnabled,
}: QuerySiteGroupPaginateEntitiesDTO): Promise<SiteGroupPaginatedResponseDTO> => {
  const result = await siteGroupApi.getSiteGroups(
    pageSize,
    orderBy,
    sortDirection,
    cursor,
    type,
    filterBy,
    accountId,
    subBrandId,
    siteId,
    siteCreationDefaultId,
    isConsentManagementEnabled,
  );

  return result.data;
};

export const getSiteGroupById = async (siteGroupId: string): Promise<SiteGroupDTO> => {
  const result = await siteGroupApi.getSiteGroupById(siteGroupId);

  return result.data;
};

export const getSiteGroupMetadata = async (
  siteGroupId: string,
  { subscriptionStatusFilter }: GetSiteGroupMetadataDTO,
): Promise<SiteGroupMetadataDTO> => {
  const result = await siteGroupApi.getSiteGroupMetadata(siteGroupId, subscriptionStatusFilter);

  return result.data;
};

export const getSiteGroupSitesById = async ({
  order,
  siteGroupId,
  queryUnmappedSites,
  page,
  pageSize,
  sortBy,
  subscriptionStatusFilter,
  domain,
}: GetSiteGroupSitesParamsDto): Promise<SitePaginatedResponseDto> => {
  const result = await siteGroupApi.getSiteGroupSitesById(
    siteGroupId,
    siteGroupId,
    queryUnmappedSites,
    page,
    pageSize,
    order,
    sortBy,
    subscriptionStatusFilter,
    domain,
  );

  return result.data;
};

export const getSiteBySiteGroupById = async (siteGroupId: string): Promise<SiteDTO> => {
  const result = await siteGroupApi.getSiteGroupTemplateSiteById(siteGroupId);

  return result.data;
};

export const promoteWorkingRemsBySiteGroupById = async (siteGroupId: string): Promise<void> => {
  await siteGroupApi.promoteSiteGroupWorkingRems(siteGroupId);
};

export const refreshSiteConfigBySiteGroupId = async ({
  siteGroupId,
  version,
}: RefreshSiteConfigsForGroupIdParamsDto): Promise<void> => {
  await siteGroupApi.refreshSiteConfigsForGroupId(version, siteGroupId);
};

export const createSiteGroup = async (dto: CreateSiteGroupDTO): Promise<SiteGroupDTO> => {
  const result = await siteGroupApi.createSiteGroup(dto);

  return result.data;
};

export const updateSiteGroup = async ({
  siteGroupId,
  ...dto
}: MutateSiteGroupDTO & { siteGroupId: string }): Promise<SiteGroupDTO> => {
  const result = await siteGroupApi.updateSiteGroup(siteGroupId, dto);

  return result.data;
};

export const deleteSiteGroup = async (siteGroupId: string): Promise<void> => {
  await siteGroupApi.deleteSiteGroup(siteGroupId);
};

export const addSitesToSiteGroup = async (siteGroupId: string, dto: AddSitesToSiteGroup): Promise<void> => {
  await siteGroupApi.addSitesToSiteGroup(siteGroupId, dto);
};

export const removeSitesFromSiteGroup = async ({
  siteGroupId,
  removeSitesFromSiteGroupDto,
}: MutateSitesToSiteGroupParams): Promise<void> => {
  await siteGroupApi.removeSitesFromSiteGroup(siteGroupId, removeSitesFromSiteGroupDto);
};

export const getIsConsentManagementEnabledStatusForSiteGroup = async (siteGroupId: string): Promise<boolean> => {
  const result = await siteGroupApi.getIsConsentManagementEnabledStatusForSiteGroup(siteGroupId);

  return result.data;
};

export const updateIsConsentManagementEnabledForSite = async (
  siteGroupId: string,
  isConsentManagementEnabled: boolean,
): Promise<SiteGroupDTO> => {
  const result = await siteGroupApi.updateIsConsentManagementEnabled(siteGroupId, {
    isConsentManagementEnabled,
  });

  return result.data;
};
