/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';

import { joyTheme } from '@audioeye/ui-theme';
import { datadogLogs } from '@datadog/browser-logs';
import GlobalStyles from '@mui/joy/GlobalStyles';
import { CssVarsProvider, Theme } from '@mui/joy/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as materialExtendTheme,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { StatsigClient } from '@statsig/js-client';
import { StatsigProvider, useFeatureGate } from '@statsig/react-bindings';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SiteUnderConstructionPage } from 'client-pages/under-construction/SiteUnderConstructionPage';
import { AudioEyeScript } from 'components/3rdPartyScripts/AudioEyeScript';
import { ChargeBeeScript } from 'components/3rdPartyScripts/ChargeBeeScript';
import { HeapScript } from 'components/3rdPartyScripts/HeapScript';
import { HubspotScript } from 'components/3rdPartyScripts/HubspotScript';
import { Authenticator } from 'components/Authenticator';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { GatsbyBrowser } from 'gatsby';
import { ReportingVersionProvider } from 'hooks/useReportingVersion';
import { useResolveStatsigUser } from 'hooks/useResolveStatsigUser';
import { memoize, merge } from 'lodash';
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { initAnalytics } from 'services/analytics/analytics';
import { AuthStorage } from 'services/api/auth/AuthStorage';
import { NotificationStoreProvider } from 'state/notifications/NotificationStoreProvider';
import { ThemeProvider } from 'theme/ThemeProvider';
import { Gate } from 'types/util';
import { AEStatsigClient } from 'util/AEStatsigClient';
import { StatsigOverrideAdaptor } from 'util/StatsigOverrideAdaptor';

import { springtimeJoyTheme } from './src/springtime/theme/springtimeJoyTheme';
import { isSpringtimeUrl } from './src/util/isSpringtimeUrl';

const materialTheme = materialExtendTheme();

const queryClient = new QueryClient();

const overrideAdapter = AEStatsigClient.getContext().options.overrideAdapter as StatsigOverrideAdaptor;
AEStatsigClient.initializeAsync().catch((error) => {
  datadogLogs.logger.error(error.message, error);
});

const WhiteScreenOfDeathFix = ({ children }: { children: React.ReactNode }) => {
  const { isLoading } = useResolveStatsigUser();

  // If we fail to load the page for whatever reason, this will clear the session and log the user out
  // See https://audioeye.atlassian.net/browse/IN-8170
  let timeout: NodeJS.Timeout | null = null;
  const resetTimeout = () => {
    if (timeout != null) {
      clearTimeout(timeout);
      timeout = null;
    }
  };
  useEffect(() => {
    if (!isLoading) {
      resetTimeout();
      return;
    }

    timeout = setTimeout(() => {
      AuthStorage.clearSession();
      overrideAdapter.removeAllOverrides();
      queryClient.clear();
    }, 6_000);

    return () => resetTimeout();
  }, [isLoading]);

  if (typeof window === 'undefined') {
    return null;
  }

  return <>{children}</>;
};

const UnderConstructionWrapper = ({ children }: { children: React.ReactNode }) => {
  const { value: shouldShowConstructionPage } = useFeatureGate(Gate.ShouldShowConstructionPage);
  if (shouldShowConstructionPage) {
    return <SiteUnderConstructionPage />;
  }
  return <>{children}</>;
};

/* Theoretically I should be able to wrap everything with a second `CssVarsProvider` but that isn't working. Instead of
 * doing a simple spread `{...joyTheme, ...springtimeJoyTheme}` this does a deep merge and memoizes the result.
 */
const mergeAndMemoizeThemes: () => Theme = memoize(
  () => merge({}, joyTheme, springtimeJoyTheme),
  () => 'static-memoize-key-for-audioeye-and-springtime-joy-themes',
);

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <>
    <Helmet htmlAttributes={{ lang: 'en-us' }} />
    <ChargeBeeScript />
    <HubspotScript />
    <HeapScript />
    <AudioEyeScript />
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
          <CssVarsProvider theme={isSpringtimeUrl() ? mergeAndMemoizeThemes() : joyTheme} defaultMode="light">
            <CssBaseline enableColorScheme>
              <GlobalStyles
                styles={{
                  '& .audioeye_ui_icon': {
                    color: 'var(--Icon-color)',
                    margin: 'var(--Icon-margin)',
                    fontSize: 'var(--Icon-fontSize, 20px)',
                    width: '1em',
                    height: '1em',
                  },
                }}
              />
              <ThemeProvider>
                <StatsigProvider client={AEStatsigClient}>
                  <WhiteScreenOfDeathFix>
                    <Authenticator>
                      <NotificationStoreProvider>
                        <ReportingVersionProvider>
                          <UnderConstructionWrapper>{element}</UnderConstructionWrapper>
                        </ReportingVersionProvider>
                      </NotificationStoreProvider>
                    </Authenticator>
                  </WhiteScreenOfDeathFix>
                </StatsigProvider>
              </ThemeProvider>
            </CssBaseline>
          </CssVarsProvider>
        </MaterialCssVarsProvider>
      </ErrorBoundary>
    </QueryClientProvider>
  </>
);

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  if (typeof window !== 'undefined') {
    initAnalytics();
  }
  if (process.env.NODE_ENV === 'development' && process.env.GATSBY_ACTIVE_ENV === 'e2e') {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const castWindow = window as unknown as Window & {
      queryClient: QueryClient;
      statsigClient: StatsigClient;
      overrideAdaptor: StatsigOverrideAdaptor;
    };
    castWindow.queryClient = queryClient;
    castWindow.statsigClient = AEStatsigClient;
    castWindow.overrideAdaptor = overrideAdapter;
  }
};

export const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] = () => {
  if (process.env.NODE_ENV === 'development' && process.env.GATSBY_ACTIVE_ENV === 'e2e') {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const castWindow = window as unknown as Window & {
      pendingStatsigGates: { gateName: string; value: boolean }[] | null;
    };

    // If gates are set before the page has rendered, add them now
    if (castWindow.pendingStatsigGates) {
      castWindow.pendingStatsigGates.map(({ gateName, value }) => overrideAdapter.overrideGate(gateName, value));
      castWindow.pendingStatsigGates = [];
    }
  }
};
