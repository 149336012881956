import {
  BannerSetDTO,
  BannerStatisticsDTO,
  CookieConsentBannerDTO,
  MutateCookieConsentBannerDTO,
  MutateDefaultBannerSetDTO,
  MutateSubBrandDTO,
  QuerySubBrandPaginateEntitiesDTO,
  SubBrandApi,
  SubBrandDTO,
  SubBrandPaginatedResponseDTO,
} from '@audioeye/mono-client';

import { APP_CONFIG } from '../../config';
import { client } from './client';

const subBrandApi = new SubBrandApi(undefined, APP_CONFIG.api.url, client);

export const getSubBrands = async ({
  pageSize,
  orderBy,
  sortDirection,
  cursor,
  accountId,
  filterBy,
}: QuerySubBrandPaginateEntitiesDTO): Promise<SubBrandPaginatedResponseDTO> => {
  const result = await subBrandApi.getSubBrands(pageSize, orderBy, sortDirection, cursor, accountId, filterBy);

  return result.data;
};

export const findOneSubBrand = async (subBrandId: string): Promise<SubBrandDTO> => {
  const result = await subBrandApi.findOneSubBrand(subBrandId);
  return result.data;
};

export const getDefaultSubBrand = async (accountId: string): Promise<SubBrandDTO> => {
  const result = await subBrandApi.getDefaultSubBrand(accountId);
  return result.data;
};

export const createSubBrand = async (dto: MutateSubBrandDTO): Promise<SubBrandDTO> => {
  const result = await subBrandApi.createSubBrand(dto);
  return result.data;
};

export const mergeSubBrand = async (fromSubBrandId: string, toSubBrandId: string): Promise<void> => {
  await subBrandApi.mergeSubBrand(fromSubBrandId, toSubBrandId);
};

export const updateSubBrand = async (subBrandId: string, dto: MutateSubBrandDTO): Promise<SubBrandDTO> => {
  const result = await subBrandApi.updateSubBrand(subBrandId, dto);
  return result.data;
};

export const removeSubBrand = async (subBrandId: string): Promise<void> => {
  await subBrandApi.removeSubBrand(subBrandId);
};

export const getDefaultBannerForSubBrand = async (subBrandId: string): Promise<CookieConsentBannerDTO> => {
  const result = await subBrandApi.getDefaultBannerForSubBrand(subBrandId);
  return result.data;
};

export const getDefaultBannerSetForSubBrand = async (subBrandId: string): Promise<BannerSetDTO> => {
  const result = await subBrandApi.getDefaultBannerSetForSubBrand(subBrandId);
  return result.data;
};

export const updateDefaultBannerSetForSubBrand = async (
  subBrandId: string,
  mutateDefaultBannerSetDto: MutateDefaultBannerSetDTO,
): Promise<BannerSetDTO> => {
  const result = await subBrandApi.updateDefaultBannerSetForSubBrand(subBrandId, mutateDefaultBannerSetDto);
  return result.data;
};

export const updateDefaultBannerForSubBrand = async (
  subBrandId: string,
  mutateCookieConsentBannerDTO: MutateCookieConsentBannerDTO,
): Promise<CookieConsentBannerDTO> => {
  const result = await subBrandApi.updateDefaultBannerForSubBrand(subBrandId, mutateCookieConsentBannerDTO);
  return result.data;
};

export const getBannerStatistics = async (subBrandId: string): Promise<BannerStatisticsDTO> => {
  const result = await subBrandApi.getBannerStatistics(subBrandId);
  return result.data;
};
