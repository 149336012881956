import {
  IntegrationApi,
  IntegrationDTO,
  IntegrationPaginatedResponseDTO,
  MutateIntegrationDTO,
  QueryIntegrationPaginateEntitiesDTO,
} from '@audioeye/mono-client';

import { APP_CONFIG } from '../../config';
import { client } from './client';

const integrationApi = new IntegrationApi(undefined, APP_CONFIG.api.url, client);

export const getIntegrations = async ({
  pageSize,
  orderBy,
  sortDirection,
  cursor,
  integrationType,
  accountId,
  filterBy,
}: QueryIntegrationPaginateEntitiesDTO): Promise<IntegrationPaginatedResponseDTO> => {
  const result = await integrationApi.getIntegrations(
    pageSize,
    orderBy,
    sortDirection,
    cursor,
    integrationType,
    accountId,
    filterBy,
  );

  return result.data;
};

export const findOneIntegration = async (integrationId: string): Promise<IntegrationDTO> => {
  const result = await integrationApi.getIntegrationById(integrationId);
  return result.data;
};

export const getIntegrationBySSOConfigId = async (integrationId: string): Promise<IntegrationDTO> => {
  const result = await integrationApi.getIntegrationBySSOConfigId(integrationId);
  return result.data;
};

export const createIntegration = async (dto: MutateIntegrationDTO): Promise<IntegrationDTO> => {
  const result = await integrationApi.createIntegration(dto);
  return result.data;
};

export const updateIntegration = async (integrationId: string, dto: MutateIntegrationDTO): Promise<IntegrationDTO> => {
  const result = await integrationApi.updateIntegration(integrationId, dto);
  return result.data;
};

export const removeIntegration = async (integrationId: string): Promise<void> => {
  await integrationApi.removeIntegration(integrationId);
};
