import { extendTheme } from '@mui/joy/styles';

export const springtimeJoyTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          '50': '#F5FDFF',
          '100': '#C6E4EB',
          '200': '#97CBD7',
          '300': '#67B3C3',
          '400': '#389AAF',
          '500': '#09819B',
          '600': '#077086',
          '700': '#055E72',
          '800': '#024D5D',
          '900': '#003B48',
        },
      },
    },
  },
});
